/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Button, MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import { GreenCircleIcon, LogoutIcon, ProfileIcon, RedCircleIcon } from "assets";
import logo from "assets/images/logo.png";
import { delStorage, getStorage } from "cache";
import { useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OIDC_Logout, selectedUser } from 'utilsFn';
import { ApiServerUrl, ConnectApiServerIcon, Header, HeaderInfo, OpenedProfileItemIcon, Username } from "./styled";

interface PanelHeaderProps {
    apiServerUrl: string
    isApiServerConnected: boolean
}

function PanelHeader({ apiServerUrl, isApiServerConnected }: PanelHeaderProps): React.ReactElement {
    const { t } = useTranslation();
    const { getIDTokenCookie } = useCookie();
    const currentCookieIDToken = getIDTokenCookie();
    const [user, setUser] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        const user = getStorage<any>("user")
        if (user) {
            setUser(user);
        } else {
            setUser(selectedUser(currentCookieIDToken))
        }
    }, []);

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <Header>
            <img
                src={logo}
                alt={t("root:project.logo")}
                height="40"
                style={{ cursor: "pointer" }}
                width="auto"
            />

            <Button
                onClick={handleClick}
            >
                <HeaderInfo>
                    <Username $isConnectedApiServer={isApiServerConnected}>
                        {user.sub}
                    </Username>

                    {isApiServerConnected
                        ? (
                            <ApiServerUrl>
                                {`${t("loadData:connected.to")} ${apiServerUrl}`}

                                <ConnectApiServerIcon src={GreenCircleIcon} />
                            </ApiServerUrl>
                        ) : (
                            <ApiServerUrl>
                                {t("loadData:api.server.not.connected")}

                                <ConnectApiServerIcon src={RedCircleIcon} />
                            </ApiServerUrl>
                        )
                    }
                </HeaderInfo>

                <OpenedProfileItemIcon src={ProfileIcon} style={{ margin: "0 0 0 16px" }} />
            </Button>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <MenuItem
                    onClick={async () =>
                    {
                        window.open(REACT_ENV.REACT_APP_DOCUMENTATION_URL, '_blank');
                    }}
                >
                    <DescriptionOutlinedIcon sx={{ margin: "0 16px 0 0" }} />

                    {t("auth:documentation.title")}
                </MenuItem>

                <MenuItem
                    onClick={async () =>
                    {
                        globalThis.location.href = REACT_ENV.REACT_APP_UI_URL_3EDGES;
                    }}
                >
                    <ArrowBackOutlinedIcon sx={{ margin: "0 16px 0 0" }} />

                    {t("auth:go.3edges")}
                </MenuItem>

                <MenuItem
                    onClick={async () =>
                    {
                        delStorage("user");
                        handleClose();
                        OIDC_Logout(currentCookieIDToken);
                    }}
                >
                    <OpenedProfileItemIcon src={LogoutIcon} style={{ margin: "0 16px 0 0" }} />

                    {t("auth:sign-out.title")}
                </MenuItem>
            </Menu>
        </Header>
    );
}

export default PanelHeader;
