import { useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import React, { useEffect } from "react";
import { base64_decode } from "utils";

function Login (): React.ReactElement {
    const { getIDTokenCookie,  } = useCookie();
    const currentCookieIDToken = getIDTokenCookie();

    useEffect(() => {
        if (currentCookieIDToken) {
            const url = `${REACT_ENV.REACT_APP_OIDC_URL}/session/end?id_token_hint=${base64_decode(currentCookieIDToken)}&force=true&post_logout_redirect_uri=${globalThis.location.origin}/login`
            globalThis.location.replace(url);
            return
        }

        globalThis.location.replace(`${REACT_ENV.REACT_APP_UI_URL_3EDGES}/login?redirectURL=${globalThis.location.origin}`);
    }, []);

    return null;
}

export default Login;
