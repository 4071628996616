/* eslint-disable react/jsx-props-no-spreading */
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Popover } from "@mui/material";
import { ICreateQueryDate } from "components/Logs/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        TimeFilterButton,
        TimeFilterContainer,
        TimeFilterSpan,
        TimePickerContainer
    } from "../TimeFilter/styled";
import { getDaysAgo, getHoursAgo, getNow } from "./utils";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toastError } from "@3edges/utils/dist/toastify";
import moment from "moment";

interface TimeFilterProps {
    setDate: (date: ICreateQueryDate) => void;
}

export function TimeFilter ({ setDate }: TimeFilterProps): React.ReactElement {
    const { t } = useTranslation();

    const [time, setTime] = useState<string>("All time");
    const [timeStart, setTimeStart] = useState<moment.Moment | null>(null);
    const [timeEnd, setTimeEnd] = useState<moment.Moment | null>(moment());
    const [customTime, setCustomTime] = useState<string | null>(null);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const isSelected = (selectedTime: string): boolean => {
        if(time === selectedTime) {
            return true
        }

        return false;
    }

    const applyTimeRange = (): void => {
        if(timeStart && timeEnd) {
            setDate({
                fromDate: timeStart.valueOf().toString(),
                toDate: timeEnd.valueOf().toString()
            })
            setTime(t("filters:time.filer.custom"))
            setFormatDateRange()

        } else {
            toastError(t("validations:time.filter.custom.error.message"))
        }
    }

    const setFormatDateRange = (): void => {
        if(timeStart && timeEnd) {
            const timeStartDate = timeStart.format("DD/MM/YYYY, HH:mm");
            const timeEndDate = timeEnd.format("DD/MM/YYYY, HH:mm");

            setCustomTime(`${timeStartDate} - ${timeEndDate}`)
        }
    }

    return (
        <TimeFilterContainer>
            <TimeFilterButton
                background={isSelected(t("filters:time.filer.all.time")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.all.time"))
                    setDate({
                        fromDate: "0",
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.all.time"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.all.time")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.1.hour")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.1.hour"))
                    setDate({
                        fromDate: getHoursAgo(1),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.1.hour"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.1.hour")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.6.hours")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.6.hours"))
                    setDate({
                        fromDate: getHoursAgo(6),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.6.hours"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.6.hours")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.12.hours")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.12.hours"))
                    setDate({
                        fromDate: getHoursAgo(12),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.12.hours"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.12.hours")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.1.day")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.1.day"))
                    setDate({
                        fromDate: getDaysAgo(1),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.1.day"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.1.day")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.2.days")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.2.days"))
                    setDate({
                        fromDate: getDaysAgo(2),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.2.days"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.2.days")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.7.days")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.7.days"))
                    setDate({
                        fromDate: getDaysAgo(7),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.7.days"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.7.days")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.30.days")) ? "#EFEFEF" : "none"}
                onClick={() => {
                    setTime(t("filters:time.filer.30.days"))
                    setDate({
                        fromDate: getDaysAgo(30),
                        toDate: getNow(),
                    })
                }}
            >

                { isSelected(t("filters:time.filer.30.days"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan> {t("filters:time.filer.30.days")} </TimeFilterSpan>
            </TimeFilterButton>

            <TimeFilterButton
                background={isSelected(t("filters:time.filer.custom")) ? "#EFEFEF" : "none"}
                onClick={(e) => {
                    handleClick(e)
                }}
                tabIndex={0}
                type="button"
            >

                { isSelected(t("filters:time.filer.custom"))
                    ? <CheckIcon sx={{ height: "17px", width: "17px", margin: "auto 4px auto auto" }} />
                    : null
                }

                <TimeFilterSpan>

                    { isSelected(t("filters:time.filer.custom"))
                        ? customTime
                        : t("filters:time.filer.custom")
                    }

                </TimeFilterSpan>

                <KeyboardArrowDownIcon sx={{ height: "17px", width: "17px", margin: "auto" }} />

            </TimeFilterButton>

            <Popover
                id={anchorEl ? "timePicker" : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
            >
                <TimePickerContainer>
                    {/* <LocalizationProvider dateAdapter={AdapterMoment}>

                        <DateTimePicker
                            label={t("filters:time.filer.start.text")}
                            value={timeStart}
                            ampm={false}
                            slotProps={{ textField: { fullWidth: true } }}
                            onChange={(newValue) => {
                                setTimeStart(newValue)
                            }}
                            disableFuture
                            sx={{
                                display: "block",
                                marginBottom: "17px",
                                width: "300px"
                            }}
                        />

                        <DateTimePicker
                            label={t("filters:time.filer.end.text")}
                            value={timeEnd}
                            ampm={false}
                            slotProps={{ textField: { fullWidth: true } }}
                            disableFuture
                            onChange={(newValue) => {
                                setTimeEnd(newValue)
                            }}
                            sx={{
                                display: "block",
                                marginBottom: "17px",
                                width: "300px"
                            }}
                        />

                        <Stack spacing={2} direction="row" justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                component="label"
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <span>{t("filters:time.filer.cancel")}</span>
                            </Button>

                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => {
                                    applyTimeRange()

                                    if(timeStart && timeEnd) {
                                        handleClose()
                                    }
                                }}
                            >
                                <span>{t("filters:time.filer.apply")}</span>
                            </Button>
                        </Stack>
                    </LocalizationProvider> */}
                </TimePickerContainer>
            </Popover>
        </TimeFilterContainer>
    );
}
