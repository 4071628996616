/* eslint-disable react/react-in-jsx-scope */
import { CookieProvider } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import ReactDOM from "react-dom/client";
import { Helmet } from 'react-helmet';
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

let CSP = REACT_ENV.REACT_APP_CONTENT_SECURITY_POLICY
CSP = CSP.replaceAll('self', "'self'")
CSP = CSP.replaceAll('unsafe-inline', "'unsafe-inline'")
CSP = CSP.replaceAll('unsafe-eval', "'unsafe-eval'")

const domNode = document.querySelector('#root');
const root = ReactDOM.createRoot(domNode)

root.render(
     <CookieProvider>
        <Helmet>
                <meta httpEquiv="Content-Security-Policy" content={CSP} />
        </Helmet>

        <App />

        <ToastContainer />
     </CookieProvider>
);

serviceWorker.unregister();
