import styled from "styled-components";

export const Header = styled.div`
    width: 100vw;
    height: 64px;
    padding: 12px 43px 12px 22px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 7px;
`;

export const Username = styled.div<any>`
    width: 100%;
    color: rgba(0,0,0,0.87);
    text-align: right;
`;

export const ApiServerUrl = styled.div`
    width: 100%;
    color: rgba(0,0,0,0.57);
    text-align: right;
    font-size: 11px;
`;



export const OpenedProfileItemIcon = styled.img.attrs((props: any) => ({
    src: props.src
}))<any>`
    width: 24px;
    height: 24px;

    box-sizing: border-box;
    object-fit: contain;
`;

export const ConnectApiServerIcon = styled.img.attrs((props: any) => ({
    src: props.src
}))`
    width: 8px;
    height: 8px;
    margin-left: 4px;

    box-sizing: border-box;
    object-fit: contain;
`;
