/* eslint-disable no-empty */
/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
/* eslint-disable unicorn/no-for-loop */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable unicorn/prefer-object-from-entries */
/* eslint-disable unicorn/prefer-string-slice */
/* eslint-disable unicorn/better-regex */
import { JwksClient } from "@3edges/utils/dist/tokenValidator/jwksClient";
import { TokenValidator } from "@3edges/utils/dist/tokenValidator/tokenValidator";
import { CSVParseError, JwksError } from "@3edges/utils/dist/tokenValidator/types";
import axios from "axios";
import { getStorage, setStorage } from "cache";
import { getCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import Cookies from "js-cookie";
import { base64_decode, getCookieDomain, isEmpty } from "utils";
import isURL from 'validator/lib/isURL';

export const OIDC_Logout = (currentCookieIdToken: string): void =>
{
    globalThis.location.href = `${REACT_ENV.REACT_APP_OIDC_URL}/session/end?id_token_hint=${base64_decode(currentCookieIdToken)}&post_logout_redirect_uri=${globalThis.location.origin}/login`
};

export const checkToken = async (token: string): Promise<any> => {
    try {
        const tokenValidator = new TokenValidator(
            { clientId: REACT_ENV.REACT_APP_OIDC_CLIENT_ID },
            REACT_ENV.REACT_APP_OIDC_URL,
            new JwksClient({ getKeys })
        );

        const verifiedToken = await tokenValidator.claim(token);

        return { status: "SUCCESS", verifiedToken };
    } catch (error) {
        return { status: "ERROR", error: error.name, message: error.message };
    }
}

const getKeys = async () => {
    const pkeyValue: any = getStorage(REACT_ENV.REACT_APP_PKEY_COOKIE_NAME)
    let cookiePKey: any = pkeyValue || getCookie(REACT_ENV.REACT_APP_PKEY_COOKIE_NAME)

    try {
        if (cookiePKey) {
            if (typeof cookiePKey === "object") {
                try {
                    cookiePKey = JSON.stringify(cookiePKey)
                } catch {}
            }

            const decodeCookie = decodeURIComponent(cookiePKey);
            const parsedCookie = JSON.parse(decodeCookie)
            return parsedCookie
        } else {
            const { data } = await axios.get(`${REACT_ENV.REACT_APP_OIDC_URL}${REACT_ENV.REACT_APP_JWKS_URI}`,
            {
                params: {
                    strictSSL: true
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!data.keys || !data?.keys?.length) {
                throw new JwksError("The JWKS endpoint did not contain any keys");
            }

            if (data) {
                const now = new Date();
                now.setTime(now.getTime() + 60 * 60 * 1000); // 1 hour

                let value;

                if (typeof value !== "string") {
                    value = JSON.stringify(data.keys);
                }

                value = value.trim();

                setStorage(REACT_ENV.REACT_APP_PKEY_COOKIE_NAME, value)

                Cookies.set(REACT_ENV.REACT_APP_PKEY_COOKIE_NAME, value, {
                    path: "/",
                    expires: now,
                    domain: getCookieDomain(),
                    secure: globalThis.location.protocol === 'https:' ? true : false,
                    sameSite: 'strict',
                });
            }

            return data.keys;
        }

    } catch {
        throw new JwksError("Failed to get certificate keys from oidc provider");
    }
}

export const decodeToken = (token: string): any => {
    const tokenValidator = new TokenValidator(
        { clientId: REACT_ENV.REACT_APP_OIDC_CLIENT_ID },
        REACT_ENV.REACT_APP_OIDC_URL,
        new JwksClient({ getKeys })
    );

    return tokenValidator.decodeClean(token);
};

export const selectedUser = (idTokenCookie: string): any =>
{
    const payload = decodeToken(base64_decode(idTokenCookie))
    const { subtype, sub } = payload
    return { subtype, sub }
}

const fnCSV = (csv) => {
    const rows = csv.split('\n').map(item => item.trim()).filter(Boolean);
    const data = [];

    for (let i = 1; i < rows.length; i++) {
        const row = rows[i]
        const columns: any = fnRow(row);
        data.push(columns);
    }

    return { header: rows[0], data };
  }

const fnRow = (row) => {
    const columns = [];
    let column = '';
    let isInQuotes = false;
    let quoteType = '';

    for (let i = 0; i < row.length; i++) {
        const char = row[i];
        const prevChar = row[i-1];
        const nextChar = row[i + 1];
        let repeated = false

        if (prevChar === "," && char === ',') {
            column += "";
        }

        if (!isInQuotes && (char === ',' || i === row.length - 1)) {
            repeated = true

            if (char !== ',') {
                column += char;
            }

            columns.push(column.trim());
            column = '';

        } else if (char === '"' || char === "'") {
            if (!isInQuotes) {
                isInQuotes = true;
                quoteType = char;
            } else if (isInQuotes && quoteType === char) {
                isInQuotes = false;
                quoteType = '';
            } else {
                column += char;
            }
        } else {
            column += char;
        }

        if (i === row.length - 1) {
            if (char === ',' && isEmpty(nextChar)) {
                columns.push("");
            } else {
                if (!repeated) {
                    columns.push(column.trim());
                }
            }
        }
    }

    return columns;
  }

export const parseCsv = (fileText: string, fileName: string): {
    fileName: string,
    rows: Array<Record<string, string>>,
    headers: Array<string>
} => {
    let entrada = fileText
    entrada = entrada.replaceAll(String.raw`\'`, "_$1$_")
    entrada = entrada.replaceAll(String.raw`\"`, "_$2$_")
    entrada = entrada.replaceAll('""', "_$3$_")
    entrada = entrada.replaceAll('\\', "_$4$_")

    const ret = fnCSV(entrada);
    const { header } = ret
    let { data } = ret

    const parsedCsv = {
        fileName,
        rows: [],
        headers: null
      }

    parsedCsv.headers = header.split(",");

    data = data.map(line =>
    {
        line = line.map(col => {
            col = col.replaceAll('_$4$_', '')
            col = col.replaceAll('_$3$_', '\"')
            col = col.replaceAll('_$2$_', '\"')
            col = col.replaceAll('_$1$_', "'")

            return encodeURIComponent(col)
        })

        const resObject = line.reduce((acc, cur, index) => {
            const key = parsedCsv.headers[index]
            return { ...acc, [key]: cur }
        }, {})

        return resObject
    })

    parsedCsv.rows = data;

    parsedCsv.rows.forEach(row => {
        const total = Object.keys(row).length

        if (parsedCsv.headers.length !== total)
        {
            console.group()
            console.log('Headers', parsedCsv.headers);
            console.log('Row', row);
            console.groupEnd();

            throw new CSVParseError('Invalid CSV')
        }
    })

    return parsedCsv
}

export const toOrigin = (url: string): string => {
    if (!url) {
      return ""
    }

    const pathArray = url.split( '/' );
    const protocol = pathArray[0];
    const host = pathArray[2];
    const origin = protocol + '//' + host;

    if(!protocol || !host) {
      return url
    }

    return origin
  };

export const validURL = (str: string): boolean => {
    if (str.startsWith("http://localhost:")) {
        return true
    }

    return isURL(str)
}

export const defineCountOfIterations = (csvLength: number, banchSize: number): void[] => {
    const template = [];
    const counts = Math.ceil(csvLength / banchSize)
    template.length = counts

    return template
}

export const dowloadFileHandler = (data: any, name: string): void => {
    const a = document.createElement("a");

    a.href = URL.createObjectURL(new Blob([JSON.stringify(data, null, "\t")], {}));
    a.setAttribute("download", `${name}.json`);

    document.body.append(a);

    a.click();
    a.remove();
};
