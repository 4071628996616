/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-multi-comp */
import { toastError } from "@3edges/utils/dist/toastify";
import { useCookie } from "contexts/cookieContext";
import { LoadDataPage } from "pages/Load";
import { Logs } from "pages/Logging";
import Login from "pages/Login";
import NotFoundPage from "pages/NotFoundPage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isWebLocalhost } from "@3edges/utils/dist/utils";
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";

function SwitchRoutes (): React.ReactElement {
    const { getAccessTokenCookie } = useCookie();
    const currentAccessToken = getAccessTokenCookie();

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [apiServerUrl, setApiServerUrl] = useState<string>(isWebLocalhost() ? 'http://localhost:4044' : '');
    const [isApiServerConnected, setIsApiServerConnected] = useState<boolean>(false);

    if (!["/login"].includes(location.pathname) && !currentAccessToken) {
        toastError(t("validations:session.expired"));
        history.push("/login")
    }

    if (!currentAccessToken) {
        return (
            <Switch>
                <Route component={Login} path="/" exact />

                <Route component={Login} path="/login" exact />

                <Route component={Login} path="/load" exact />

                <Route component={Login} path="/logs" exact />

                <Route path="*" component={NotFoundPage} />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route render={(props) => <LoadDataPage {...props} apiServerUrl={apiServerUrl} setApiServerUrl={setApiServerUrl} isApiServerConnected={isApiServerConnected} setIsApiServerConnected={setIsApiServerConnected} />} path="/" exact />

            <Route render={(props) => <LoadDataPage {...props} apiServerUrl={apiServerUrl} setApiServerUrl={setApiServerUrl} isApiServerConnected={isApiServerConnected} setIsApiServerConnected={setIsApiServerConnected} />} path="/load" exact />

            <Route render={(props) => <Logs {...props} apiServerUrl={apiServerUrl} isApiServerConnected={isApiServerConnected} />} path="/logs" exact />

            <Route component={Login} path="/login" exact />

            <Route path="*" component={() => <NotFoundPage isAuth />} />
        </Switch>
    );
}

function Routes (): React.ReactElement {
    return (
        <BrowserRouter>
            <SwitchRoutes />
        </BrowserRouter>
    );
}

export default Routes;
