/* eslint-disable unicorn/prefer-node-protocol */
/* eslint-disable unicorn/prefer-module */
declare global {
    interface Window {
        _env: Partial<NodeJS.ProcessEnv>;
    }
}

globalThis.Buffer = globalThis.Buffer || require('buffer').Buffer;

export const REACT_ENV: any = { ...process.env, ...globalThis._env }
