import styled from "styled-components";

export const TimeFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: none;
    flex-wrap: wrap;
    align: right;
    margin-right: 7px;
`;

export const TimeFilterButton = styled.button.attrs((props: any) => ({
    background: props.background
}) as any)<any>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: none;
    flex-wrap: wrap;
    border: none;
    height: 21px;
    cursor: pointer;
    background: ${(props) => props.background};

    &:hover {
        background: #DDDDDD;
    }

    &:active {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
`;

export const TimeFilterSpan = styled.span`
    border-radius: 4px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
`;

export const TimePickerContainer = styled.div`
    margin: 17px 14px 14px 14px;
    justify-content: space-between;
    display: block;
    width: 300px;
`;

